import React, { useState, useEffect, useRef } from 'react';
import { Sling as Hamburger } from 'hamburger-react';
import { useFirestore } from 'react-redux-firebase';
import { Typography, MenuItem, Select, FormControl, InputLabel, Paper, IconButton } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import SignOut from '../auth/SignOut';
import moment from 'moment';

const HamburgerMenu = ({
  auth,
  profile,
  setWorkstations,
  setServers,
  setSelectedClient,
  setSelectedProject,
  setCustomTitle,
  setURL,
  configuration,
  selectedClient,
  selectedProject
}) => {
  const firestore = useFirestore();
  const [isOpen, setOpen] = useState(true);
  const [groupedProjects, setGroupedProjects] = useState({});
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [clientNames, setClientNames] = useState({});
  const [activeStatsIcon, setActiveStatsIcon] = useState(null);

  const projectUnsubscribeRef = useRef(null);

  useEffect(() => {
    return () => {
      if (projectUnsubscribeRef.current) {
        projectUnsubscribeRef.current();
      }
    };
  }, []);

  const monthOrder = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const styles = {
    container: {
      position: 'absolute',
      left: '50px',
      width: '300px',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      padding: '5px'
    }
  };


  useEffect(() => {
    if (auth.uid && profile) {
      const loadClients = async () => {
        try {
          if (profile.role === 'admin' || profile.role === 'supervisor') {
            const clientSnapshot = await firestore.collection('clients').get();
            const allClients = clientSnapshot.docs.map(clientDoc => ({
              id: clientDoc.id,
              name: clientDoc.data().name,
            }));

            const sortedClients = allClients.sort((a, b) => a.name.localeCompare(b.name));
            const allProjects = await Promise.all(
              sortedClients.map(async (client) => {
                const projectsSnapshot = await firestore.collection('clients').doc(client.id).collection('projects').get();
                const projects = projectsSnapshot.docs.map(projectDoc => ({
                  ...projectDoc.data(),
                  clientID: client.id,
                  date: projectDoc.data().date.toDate(),
                  id: projectDoc.id
                }));
                return { clientID: client.id, projects };
              })
            );
            const grouped = groupProjectsByYearAndMonth(allProjects);
            setClientNames(sortedClients.reduce((acc, client) => ({ ...acc, [client.id]: client.name }), {}));
            setGroupedProjects(grouped);
          } else {
            const userDoc = await firestore.collection('users').doc(auth.uid).get();
            const data = userDoc.data();
            const clientScope = data.clientScope;

            if (!Array.isArray(clientScope) || clientScope.length === 0) {
              console.error('No clients available in clientScope');
              return;
            }

            const clientPromises = clientScope.map(async (clientID) => {
              const clientDoc = await firestore.collection('clients').doc(clientID).get();
              if (!clientDoc.exists) {
                console.error(`Client with ID ${clientID} does not exist`);
                return null;
              }

              const clientData = clientDoc.data();
              setClientNames((prev) => ({ ...prev, [clientID]: clientData.name }));

              const projectSnapshot = await firestore.collection('clients').doc(clientID).collection('projects').get();
              const projects = projectSnapshot.docs.map((projectDoc) => ({
                ...projectDoc.data(),
                clientID,
                date: projectDoc.data().date.toDate(),
                id: projectDoc.id
              }));

              return { clientID, projects };
            });

            const clientsProjects = await Promise.all(clientPromises);
            const grouped = groupProjectsByYearAndMonth(clientsProjects);
            setGroupedProjects(grouped);
          }
        } catch (error) {
          console.error('Error fetching clients and projects:', error);
        }
      };

      loadClients();
    }
  }, [isOpen, firestore, auth.uid, profile]);

  useEffect(() => {
    if (!selectedClient) return;
  
    const fetchClientProjects = async (clientIDs) => {
      try {
        const projectPromises = clientIDs.map(async (clientID) => {
          const clientRef = firestore.collection('clients').doc(clientID);
          const clientDoc = await clientRef.get();
  
          if (!clientDoc.exists) {
            console.error(`Client with ID ${clientID} does not exist`);
            return null;
          }
  
          const projectsSnapshot = await clientRef.collection('projects').get();
          return {
            clientID,
            projects: projectsSnapshot.docs.map((projectDoc) => ({
              ...projectDoc.data(),
              id: projectDoc.id,
              clientID,
              date: projectDoc.data().date.toDate(),
            })),
          };
        });
  
        const allProjects = (await Promise.all(projectPromises)).filter(Boolean);
        setGroupedProjects(groupProjectsByYearAndMonth(allProjects));
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
  
    let unsubscribe;
  
    if (selectedClient === 'all') {
      const isAdminOrSupervisor = profile?.role === 'admin' || profile?.role === 'supervisor';
  
      if (isAdminOrSupervisor) {
        // Admin/Supervisor: Fetch all clients
        unsubscribe = firestore.collection('clients').onSnapshot(async (snapshot) => {
          const clientIDs = snapshot.docs.map((doc) => doc.id);
          await fetchClientProjects(clientIDs);
        });
      } else {
        // Regular user: Fetch only scoped clients
        unsubscribe = firestore.collection('users').doc(auth.uid).onSnapshot(async (userDoc) => {
          const clientScope = userDoc.data()?.clientScope || [];
  
          if (clientScope.length === 0) {
            console.error('No clients available in clientScope');
            return;
          }
  
          await fetchClientProjects(clientScope);
        });
      }
    } else {
      // Fetch projects for a specific client in real-time
      unsubscribe = firestore
        .collection('clients')
        .doc(selectedClient)
        .collection('projects')
        .onSnapshot(
          (snapshot) => {
            const projects = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
              clientID: selectedClient,
              date: doc.data().date.toDate(),
            }));
            setGroupedProjects(groupProjectsByYearAndMonth([{ clientID: selectedClient, projects }]));
          },
          (error) => {
            console.error('Error fetching projects in real-time:', error);
          }
        );
    }
  
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [firestore, selectedClient, auth.uid, profile]);
  


  const groupProjectsByYearAndMonth = (clientsProjects) => {
    return (clientsProjects || []).reduce((acc, { clientID, projects }) => {
      if (!projects) return acc;

      // Create sub-structure for each client
      if (clientID) {
        acc[clientID] = projects.reduce((yearAcc, projectData) => {
          const year = projectData.date.getFullYear();
          const month = projectData.date.toLocaleString('default', { month: 'long' });

          if (!yearAcc[year]) {
            yearAcc[year] = {};
          }
          if (!yearAcc[year][month]) {
            yearAcc[year][month] = [];
          }

          yearAcc[year][month].push(projectData);
          yearAcc[year][month].sort((a, b) => a.date - b.date);

          return yearAcc;
        }, {});
      }

      // Also store in "all"
      acc['all'] = acc['all'] || {};
      projects.forEach((projectData) => {
        const year = projectData.date.getFullYear();
        const month = projectData.date.toLocaleString('default', { month: 'long' });

        if (!acc['all'][year]) {
          acc['all'][year] = {};
        }
        if (!acc['all'][year][month]) {
          acc['all'][year][month] = [];
        }

        acc['all'][year][month].push(projectData);
        acc['all'][year][month].sort((a, b) => a.date - b.date);
      });

      return acc;
    }, {});
  };
  
  const handleClientClick = (clientID) => {
    if (projectUnsubscribeRef.current) {
      projectUnsubscribeRef.current();
      projectUnsubscribeRef.current = null;
    }

    setSelectedClient(clientID);
    setCustomTitle(clientID === 'all' ? 'All Companies' : clientNames[clientID]);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedProject(null);
    setWorkstations([]);
    setServers([]);
    setActiveStatsIcon(null);
  };

  const handleYearClick = (year) => {
    setSelectedYear(selectedYear === year ? null : year);
    setSelectedMonth(null);
  };

  const handleMonthClick = (year, month) => {
    setSelectedYear(year);
    setSelectedMonth(selectedMonth === month ? null : month);
  };

    const handleProjectClick = (projectId, clientID) => {
    // If clicking the same project, toggle it off
    if (selectedProject?.id === projectId) {
      // Unsubscribe from any previous subscription
      if (projectUnsubscribeRef.current) {
        projectUnsubscribeRef.current();
        projectUnsubscribeRef.current = null;
      }

      setSelectedProject(null);
      setWorkstations([]);
      setServers([]);
      setCustomTitle(`${clientNames[clientID]}`);
      setActiveStatsIcon(null);
      return;
    }

    // Otherwise, we're selecting a new project
    // 1) Unsubscribe from any old subscription first
    if (projectUnsubscribeRef.current) {
      projectUnsubscribeRef.current();
      projectUnsubscribeRef.current = null;
    }

    // 2) Fetch the new project from Firestore
    const projectRef = firestore
      .collection('clients')
      .doc(clientID)
      .collection('projects')
      .doc(projectId);

    projectRef.get()
      .then((projectDoc) => {
        if (projectDoc.exists) {
          const projectData = projectDoc.data();
          setSelectedProject({ ...projectData, id: projectDoc.id });

          const date = moment.unix(projectData.date.seconds).format('MMMM D, YYYY');
          setCustomTitle(`${clientNames[clientID]} : ${date}`);

          const refLocation = projectData?.refLocation?.path;
          if (!refLocation) {
            console.error("No 'refLocation' in this project document");
            return;
          }

          // Build a project URL for some external usage
          const config = configuration || { scheme: 'https', host: 'murphymckay.online' };
          const projectURL = `${config.scheme}://${config.host}/${refLocation}`;
          setURL(projectURL);

          // Decide if "servers" or "workstations"
          const inferredType = refLocation.split('/').includes('serverProjects')
            ? 'servers'
            : 'workstations';

          // 3) Set up a new subscription to the correct sub-collection
          const itemsRef = firestore.collection(`${refLocation}/${inferredType}`);
          const unsubscribe = itemsRef.onSnapshot((snapshot) => {
            const itemsData = snapshot.docs.map((doc) => doc.data());
            if (inferredType === 'servers') {
              setServers(itemsData);
              setWorkstations([]); // Clear the other array
            } else {
              setWorkstations(itemsData);
              setServers([]); // Clear the other array
            }
          });

          // 4) Save the unsubscribe function so we can call it later
          projectUnsubscribeRef.current = unsubscribe;
        }
      })
      .catch((error) => {
        console.error('Error fetching project:', error);
      });
  };
  

  const handleYearStatsClick = async (year, e) => {
    e.stopPropagation();

    if (projectUnsubscribeRef.current) {
      projectUnsubscribeRef.current();
      projectUnsubscribeRef.current = null;
    }

    setActiveStatsIcon(activeStatsIcon === `year-${year}` ? null : `year-${year}`);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedProject(null);

    if (selectedClient === 'all') {
        setCustomTitle(`All Companies: ${year}`);
    } else {
        setCustomTitle(`${clientNames[selectedClient]}: ${year}`);
    }

    if (groupedProjects[selectedClient] && groupedProjects[selectedClient][year]) {
      const months = groupedProjects[selectedClient][year];

      // Collect objects of shape: { type: 'servers' | 'workstations', promise: Promise }
      const itemsFetches = [];

      Object.entries(months).forEach(([month, projects]) => {
        projects.forEach((projectData) => {
          const refLocation = projectData.refLocation.path;

          if (refLocation) {
            const inferredType = refLocation.split('/').includes('serverProjects')
              ? 'servers'
              : 'workstations';

            const itemsRef  = firestore.doc(refLocation).collection(inferredType);

            // Push an object containing both the type and the actual fetch promise
            itemsFetches.push({
              type: inferredType,
              promise: itemsRef.get()
            });

          }
        });
      });

      try {
        const snapshots = await Promise.all(itemsFetches.map(({ promise }) => promise));

        const allWorkstations = [];
        const allServers = [];

        snapshots.forEach((snapshot, index) => {
          const { type } = itemsFetches[index];
          const docsData = snapshot.docs.map(doc => doc.data());

          if (type === 'workstations') {
            allWorkstations.push(...docsData);
          } else {
            allServers.push(...docsData);
          }
        });

        // Update both states accordingly
        setWorkstations(allWorkstations);
        setServers(allServers);

      } catch (error) {
        console.error('Error fetching workstations for the year:', error);
      }
    } else {
      console.log('No projects found for this year.');
    }
  };

  const handleMonthStatsClick = async (year, month, e) => {
    e.stopPropagation();
  
    if (projectUnsubscribeRef.current) {
      projectUnsubscribeRef.current();
      projectUnsubscribeRef.current = null;
    }

    // Toggle the active icon for the selected month
    setActiveStatsIcon(
      activeStatsIcon === `month-${year}-${month}` ? null : `month-${year}-${month}`
    );
  
    // Reset selections and state
    setSelectedMonth(null);
    setSelectedProject(null);
    setWorkstations([]);
    setServers([]);
  
    // Set a custom title based on the selected client
    if (selectedClient === 'all') {
      setCustomTitle(`All Companies: ${month} ${year}`);
    } else {
      setCustomTitle(`${clientNames[selectedClient]}: ${month} ${year}`);
    }
  
    // Check if there are projects for this particular client, year, and month
    const clientProjects = groupedProjects[selectedClient];
    if (clientProjects && clientProjects[year] && clientProjects[year][month]) {
      const projects = clientProjects[year][month];
  
      // Collect objects of shape { type: 'servers' | 'workstations', promise }
      const itemsFetches = [];
  
      projects.forEach((projectData) => {
        const refLocation = projectData.refLocation.path;
  
        if (refLocation) {
          // Infer whether we're dealing with servers or workstations
          const inferredType = refLocation.split('/').includes('serverProjects')
            ? 'servers'
            : 'workstations';
  
          // Reference the correct sub-collection
          const itemsRef = firestore.doc(refLocation).collection(inferredType);
  
          // Push an object containing both the type and the actual get() promise
          itemsFetches.push({
            type: inferredType,
            promise: itemsRef.get(),
          });
        }
      });
  
      try {
        // Await all Firestore fetches
        const snapshots = await Promise.all(itemsFetches.map(item => item.promise));
  
        // Separate arrays for servers and workstations
        const allWorkstations = [];
        const allServers = [];
  
        // Match each snapshot to its inferred type
        snapshots.forEach((snapshot, index) => {
          const { type } = itemsFetches[index];
          const docsData = snapshot.docs.map(doc => doc.data());
  
          if (type === 'workstations') {
            allWorkstations.push(...docsData);
          } else {
            allServers.push(...docsData);
          }
        });
  
        // Update state with the fetched data
        setWorkstations(allWorkstations);
        setServers(allServers);
  
      } catch (error) {
        console.error('Error fetching items for the month:', error);
      }
    } else {
      console.log('No projects found for this month.');
    }
  };

  function formatDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  return (
    <div>
      <Hamburger toggled={isOpen} toggle={setOpen} />
      {isOpen && (
        <div style={styles.container}>
          {Object.keys(clientNames).length > 0 && (
            <FormControl fullWidth>
              <InputLabel>Select Company</InputLabel>
              <Select
                value={selectedClient || ''}
                onChange={(e) => handleClientClick(e.target.value)}  // Still handle the change event
                label="Select Company"
              >
                {Object.keys(clientNames).length > 1 && (profile.role === 'admin' || profile.role === 'supervisor') && (
                  <MenuItem 
                    value="all"
                    onClick={() => handleClientClick('all')} // Use onClick to detect same value selection
                  >
                    All Companies
                  </MenuItem>
                )}
                {Object.keys(clientNames || {}).map((clientID) => (
                  <MenuItem 
                    key={clientID} 
                    value={clientID}
                    onClick={() => handleClientClick(clientID)} // Use onClick to detect same client re-selection
                  >
                    {clientNames[clientID]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedClient && groupedProjects[selectedClient] && (
            <ul style={{ padding: '0', marginBottom: '20px', listStyleType: 'none', textAlign: 'center' }}>
              {Object.entries(groupedProjects[selectedClient])
                .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA))
                .map(([year, months]) => (
                  <li key={year} style={{ marginBottom: '10px', position: 'relative', marginLeft: '0', marginRight: '0' }}>
                    <Paper variant="outlined" sx={{ padding: 1, border: '1px solid #ccc', borderRadius: '8px', width: '90%', margin: '0 auto' }}>
                      <Paper
                        elevation={3}
                        sx={{
                          cursor: 'pointer',
                          textAlign: 'center',
                          margin: '0 auto',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '8px 16px',
                          position: 'relative',
                        }}
                        onClick={() => handleYearClick(year)}
                      >
                        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                          {year}
                        </Typography>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: activeStatsIcon === `year-${year}` ? '#1976d2' : 'black',
                          }}
                          onClick={(e) => handleYearStatsClick(year, e)}
                        >
                          <BarChartIcon />
                        </IconButton>
                      </Paper>
                      {selectedYear === year && (
                        <ul style={{ padding: '0', marginTop: '10px', listStyleType: 'none', textAlign: 'center' }}>
                          {Object.entries(months)
                            .sort((a, b) => monthOrder.indexOf(a[0]) - monthOrder.indexOf(b[0]))
                            .map(([month, projects]) => (
                              <li key={month} style={{ marginBottom: '10px', marginLeft: '0', marginRight: '0' }}>
                                <Paper
                                  elevation={3}
                                  sx={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    margin: '0 auto',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '8px 16px',
                                    position: 'relative',
                                  }}
                                  onClick={() => handleMonthClick(year, month)}
                                >
                                  <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                                    {month}
                                  </Typography>
                                  <IconButton
                                    sx={{
                                      position: 'absolute',
                                      right: 8,
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      color: activeStatsIcon === `month-${year}-${month}` ? '#1976d2' : 'black',
                                    }}
                                    onClick={(e) => handleMonthStatsClick(year, month, e)}
                                  >
                                    <BarChartIcon />
                                  </IconButton>
                                </Paper>
                                {selectedMonth === month && (
                                  <ul style={{ padding: '0', marginTop: '10px', listStyleType: 'none', textAlign: 'center' }}>
                                    {projects
                                      .sort((a, b) => {
                                        const dateA = a.date instanceof Date ? a.date : a.date.toDate();
                                        const dateB = b.date instanceof Date ? a.date : a.date.toDate();
                                        return dateA - dateB;
                                      })
                                      .map((projectData, index) => {
                                        const date = projectData.date instanceof Date ? projectData.date : projectData.date.toDate();
                                        const dayWithSuffix = formatDayWithSuffix(date.getDate());
                                        return (
                                          <li key={index} style={{ marginBottom: '10px', marginLeft: '0', marginRight: '0' }}>
                                            <Paper
                                              elevation={3}
                                              sx={{
                                                color: selectedProject?.id === projectData.id ? 'white' : 'black',
                                                backgroundColor: selectedProject?.id === projectData.id ? '#1976d2' : '#FFF',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                margin: '0 auto',
                                                width: '100%',
                                              }}
                                              onClick={() => handleProjectClick(projectData.id, projectData.clientID)}
                                            >
                                              <Typography variant="body1">
                                                {`${dayWithSuffix}`}
                                              </Typography>
                                            </Paper>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                )}
                              </li>
                            ))}
                        </ul>
                      )}
                    </Paper>
                  </li>
                ))}
            </ul>
          )}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <SignOut auth={auth} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
