import { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, Navigate, Link } from 'react-router-dom';
import { Container, Box, Card, TextField, Grid, Button } from '@mui/material';
import Badge from '@mui/icons-material/Badge';
import { grey } from '@mui/material/colors';

import { signIn } from '../../store/actions/authActions';
import mmaLogo from '../../assets/mma-logo.png';

class SignIn extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = (e) => {
    this.setState({ successful_submit: false });
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    this.props.signIn({ email, password });
  };

  render() {
    const { auth } = this.props;
    if (auth.uid) return <Routes><Route path="*" element={<Navigate replace to="/" />} /></Routes>;

    return (
      <Container maxWidth="md">
        <form onSubmit={this.handleSubmit}>
          <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
            <Grid container display="flex" justifyContent="center" alignItems="center" padding="10px" spacing={0}>
              <Grid>
                <Card>
                  <Grid container display="flex" justifyContent="center" alignItems="center" padding="10px" spacing={1}>
                    <Grid item xs={2}>
                      <Badge fontSize="large" sx={{ color: grey[600] }} />
                    </Grid>

                    <Grid item xs={10}>
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <TextField
                          label="Email"
                          name="email"
                          variant="outlined"
                          onChange={this.handleChange}
                          type="email"
                          required
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Password"
                          name="password"
                          variant="outlined"
                          onChange={this.handleChange}
                          type="password"
                          required
                        />
                      </Grid>

                      <Button type="submit">Login</Button>
                      <Button component={Link} to="/signup">Sign Up</Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img alt="murphy mckay logo" src={mmaLogo} style={{ maxWidth: 100 }} />
                </div>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
