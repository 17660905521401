import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, LinearProgress, Container } from '@mui/material';

  function ProjectAnalyticsThin({ workstations }) {
  const [progress, setProgress] = useState(0);
  const [totals, setTotals] = useState({
    desks: 0, workstations: 0, computers: 0, monitors: 0, dockingStations: 0, KVMs: 0, 
    printers: 0, switches: 0, keyboards: 0, mice: 0, headsets: 0, powerAdaptors: 0, phones: 0,
    speakers: 0, additional: 0, webcams: 0, wirelessReceivers: 0, disconnected: 0, reconnected: 0, QAed: 0,
    issuesEncountered: 0, issuesSolved: 0
  });

  useEffect(() => {
    const newTotals = {
      desks: Object.keys(workstations).length, workstations: Object.keys(workstations).length, computers: 0, monitors: 0, dockingStations: 0, KVMs: 0,
      printers: 0, switches: 0, keyboards: 0, mice: 0, headsets: 0, powerAdaptors: 0, phones: 0,
      speakers: 0, additional: 0, webcams: 0, wirelessReceivers: 0, disconnected: 0, reconnected: 0, QAed: 0,
      issuesEncountered: 0, issuesSolved: 0
    };

    Object.values(workstations).forEach((ws) => {
      newTotals.desks -= !ws.documentBy || ws.documentBy === "" ? 1 : 0;
      newTotals.workstations -= !ws.documentBy || ws.documentBy === "" ? 1 : 0;
      
      if (ws.disconnectOnly) newTotals.workstations -= 1;
      newTotals.computers += Object.keys(ws.computers || {}).length;
      newTotals.monitors += Object.keys(ws.monitors || {}).length;
      newTotals.dockingStations += Object.keys(ws.dockingStations || {}).length;
      newTotals.KVMs += Object.keys(ws.kvms || {}).length;
      newTotals.printers += Object.keys(ws.printers || {}).length;
      newTotals.switches += Object.keys(ws.switches || {}).length;
      newTotals.keyboards += Object.keys(ws.peripherals?.keyboards || {}).length;
      newTotals.mice += Object.keys(ws.peripherals?.mice || {}).length;
      newTotals.headsets += Object.keys(ws.peripherals?.headsets || {}).length;
      newTotals.powerAdaptors += Object.keys(ws.peripherals?.powerAdaptors || {}).length;
      newTotals.phones += Object.keys(ws.peripherals?.phones || {}).length;
      newTotals.speakers += Object.keys(ws.peripherals?.speakers || {}).length;
      newTotals.additional += Object.keys(ws.peripherals?.additionalPeripherals || {}).length;
      newTotals.webcams += Object.keys(ws.peripherals?.webcams || {}).length;
      newTotals.wirelessReceivers += Object.keys(ws.peripherals?.wirelessReceivers || {}).length;
      newTotals.disconnected += 1;
      newTotals.reconnected += ws.reconnect?.completedBy ? 1 : 0;
      newTotals.QAed += ws.qa?.completedBy ? 1 : 0;
      newTotals.issuesEncountered += Object.values(ws.issues || {}).filter((issue) => issue.clientIssue === true).length;
      newTotals.issuesSolved += Object.values(ws.issues || {}).filter((issue) => issue.clientIssue === true && issue.resolvedBy).length;
    });

    setTotals(newTotals);
    const { disconnected, reconnected, QAed, workstations: totalWorkstations } = newTotals;
    const progressValue = (totalWorkstations > 0) ? ((disconnected * 0.3 + reconnected * 0.6 + QAed * 0.1) / totalWorkstations) * 100 : 0;
    setProgress(progressValue);
  }, [workstations]);

  // Define a consistent mapping between labels and their corresponding totals keys
  const labelToKeyMapping = {
    'Desks': 'desks',
    'Computers': 'computers',
    'Monitors': 'monitors',
    'Docking Stations': 'dockingStations'
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch" marginBottom="22px">
        {Object.entries(labelToKeyMapping).map(([label, key]) => (
          <Grid item xs={12} sm={6} md={3} key={label}>
            <Paper elevation={2} sx={{ padding: 2 }}>
              <Typography variant="h6" textAlign="center">{label}</Typography>
              <Typography variant="h5" textAlign="center">{totals[key]}</Typography>
            </Paper>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Typography variant="h5" textAlign="center">Devices and Peripherals</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="body1" textAlign="center">Printers: {totals.printers}</Typography>
                <Typography variant="body1" textAlign="center">Switches: {totals.switches}</Typography>
                <Typography variant="body1" textAlign="center">KVMs: {totals.KVMs}</Typography>
                <Typography variant="body1" textAlign="center">Additional: {totals.additional}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1" textAlign="center">Keyboards: {totals.keyboards}</Typography>
                <Typography variant="body1" textAlign="center">Mice: {totals.mice}</Typography>
                <Typography variant="body1" textAlign="center">Speakers: {totals.speakers}</Typography>
                <Typography variant="body1" textAlign="center">Wireless Receivers: {totals.wirelessReceivers}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1" textAlign="center">Headsets: {totals.headsets}</Typography>
                <Typography variant="body1" textAlign="center">Power Adaptors: {totals.powerAdaptors}</Typography>
                <Typography variant="body1" textAlign="center">Phones: {totals.phones}</Typography>
                <Typography variant="body1" textAlign="center">Webcams: {totals.webcams}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ProjectAnalyticsThin;