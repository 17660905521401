import React, { useState } from 'react';
import { Accordion, AccordionSummary, Typography, Grid, Modal, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BasicInfo from './BasicInfo';

function Servers({ servers }) {
  const [openModal, setOpenModal] = useState(false);
  const [currentServer, setCurrentServer] = useState(null);

  const handleOpenModal = (server) => {
    setCurrentServer(server);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentServer(null);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto'
  };

  if (!servers) return null;

  return (
    <>
      {Object.entries(servers).map(([id, server]) => (
        <Accordion key={id} expanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${id}-content`}
            id={`panel${id}-header`}
            onClick={() => handleOpenModal(server)}
          >
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={4}><Typography variant="body1">{server.type}</Typography></Grid>
              <Grid item xs={4}><Typography variant="body1">{server.model}</Typography></Grid>
              {/* <Grid item xs={4}><Typography variant="body1">{server.serialNumber}</Typography></Grid> */}
              <Grid item xs={3.7}><Typography variant="body1">{server.destinationRack}</Typography></Grid>
              {/* <Grid item xs={3}><Typography variant="body1">{server.destinationRackUnit}</Typography></Grid> */}
              {/* <Grid item xs={0}><Typography variant="body1">{server.destinationRow}</Typography></Grid> */}
            </Grid>
          </AccordionSummary>
        </Accordion>
      ))}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          {currentServer && <BasicInfo server={currentServer} />}
        </Box>
      </Modal>
    </>
  );
}

export default Servers;
