import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

function ProjectDialog({ 
    open, 
    onClose, 
    projectName, 
    setProjectName, 
    projectDate, 
    setProjectDate, 
    projectStartTime, 
    setProjectStartTime, 
    projectType, 
    setProjectType, 
    handleSubmit 
}) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Project</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the details for the new project.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Project Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Project Date"
                    type="date"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={projectDate}
                    onChange={(e) => setProjectDate(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Project Start Time"
                    type="time"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={projectStartTime}
                    onChange={(e) => setProjectStartTime(e.target.value)}
                />
                <FormControl margin="dense" fullWidth>
                    <FormLabel>Project Type</FormLabel>
                    <RadioGroup
                        row
                        value={projectType}
                        onChange={(e) => setProjectType(e.target.value)}
                    >
                        <FormControlLabel value="workstations" control={<Radio />} label="Workstations" />
                        <FormControlLabel value="servers" control={<Radio />} label="Servers" />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProjectDialog;
