import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useFirestore } from 'react-redux-firebase';
import { firestoreConnect } from 'react-redux-firebase';
import {
  Typography, Container, Button, AppBar, Tabs, Tab, Box, useTheme, InputBase, IconButton, Checkbox, FormControlLabel, Snackbar, Menu, MenuItem, Modal, RadioGroup, FormControl, FormLabel, Radio, FormHelperText
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ProjectAnalytics from './ProjectAnalytics';
import ServerProjectAnalytics from './ServerProjectAnalytics';
import Workstations from './workstations/Workstations';
import mmaLogo from '../../assets/mma-logo.png';
import Papa from 'papaparse';
import Title from './Title';
import moment from 'moment';
import UploadModal from './Modals/UploadModal';
import { v4 as uuidv4 } from 'uuid'

const tabLabels = ['username', 'destination', 'issues', 'status'];
const colorPriority = { red: 1, orange: 2, yellow: 3, greenDisconnect: 4, greenQA: 5, greenWithRedBorder: 6, white: 7, black: 8 };

const getColorPriority = (workstation) => {
  if (workstation.qa && workstation.qa.completedBy) {
    if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) {
      return 'greenWithRedBorder';
    }
    return 'greenQA';
  }
  if (workstation.disconnectOnly) return 'greenDisconnect';

  // For import only
  if (!workstation.documentBy || workstation.documentBy === "") return 'black';

  if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) return 'red';
  
  return workstation.reconnect?.completedBy ? 'yellow' : 'orange';
};

const compareValues = (a, b, key) => {
  if (!a || !b) return 0; // Ensure both objects exist

  switch (key) {
    case 'issues':
      return Object.values(a.issues || {}).filter(issue => issue.clientIssue).length - 
             Object.values(b.issues || {}).filter(issue => issue.clientIssue).length;
    case 'status':
      return colorPriority[getColorPriority(a)] - colorPriority[getColorPriority(b)];
    default:
      return (a[key] || '').localeCompare(b[key] || ''); // Safely access string properties
  }
};

const sortWorkstations = (workstations, sortConfig) => {
  return Object.values(workstations || {}).filter(Boolean).sort((a, b) => {
    const comparison = compareValues(a, b, sortConfig.key);
    return sortConfig.direction === 'asc' ? comparison : -comparison;
  });
};


const filterWorkstations = (workstations, filters, searchQuery, sortConfig) => {
  return workstations.filter((workstation) => {
    const value = workstation[sortConfig.key] ?? '';
    const matchesSearch = searchQuery
      ? value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    const matchesFilters = (!filters.filterByComputers || Object.keys(workstation.computers || {}).length > 0)
      && (!filters.filterByDockingStations || Object.keys(workstation.dockingStations || {}).length > 0)
      && (!filters.status.disconnectOnly || workstation.disconnectOnly)
      && (!filters.status.qa || (workstation.qa && workstation.qa.completedBy))
      && (!filters.status.issue || (workstation.issues && Object.values(workstation.issues).some(issue => issue.clientIssue && !issue.resolvedBy)))
      && (!filters.status.reconnected || workstation.reconnect?.completedBy);

    return matchesSearch && matchesFilters;
  });
};

function Project({ auth, profile, projectSummary, workstations }) {
  const theme = useTheme();
  const [usersData, setUsersData] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: 'username', direction: 'asc' });
  const [showSearch, setShowSearch] = useState({ username: false, destination: false });
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [filterByComputers, setFilterByComputers] = useState(false);
  const [filterByDockingStations, setFilterByDockingStations] = useState(false);
  const [statusFilter, setStatusFilter] = useState({
    disconnectOnly: false,
    qa: false,
    issue: false,
    reconnected: false
  });
  const [tabPosition, setTabPosition] = useState({ left: 0, width: 0 });
  const [linkCopied, setLinkCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUsernameFilter, setAnchorElUsernameFilter] = useState(null);
  const tabsRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [accessType, setAccessType] = useState(projectSummary?.public ? 'public' : 'restricted');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const firestore = useFirestore();

  useEffect(() => {
    if (auth.uid) {
      firestore.collection('users').get().then((snapshot) => {
        const users = snapshot.docs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.data() }), {});
        setUsersData(users);
      }).catch((error) => console.error('Error fetching users:', error));
    }
  }, [firestore, auth.uid]);

  useEffect(() => {
    updateTabPosition(sortConfig.key);
  }, [sortConfig.key]);

  useEffect(() => {
    if (showSearch.username || showSearch.destination || showFilter) {
      updateTabPosition(sortConfig.key);
    }
  }, [showSearch, showFilter]);

  const togglePublicStatus = () => {
    firestore.doc(`${projectSummary.path}/${projectSummary.id}`).update({ public: accessType === 'public' })
      .catch((error) => console.error('Error updating public status:', error));
  };

  const handleTabChange = (event, newValue) => {
    setSortConfig({ key: newValue, direction: 'asc' });
    setShowSearch({ username: false, destination: false });
    setSearchQuery('');
    setShowFilter(false);
    updateTabPosition(newValue);
  };

  const handleTabClick = (key) => {
    setSortConfig((prev) => ({
      key, direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
    updateTabPosition(key);
  };

  const toggleSearch = (event, key) => {
    event.stopPropagation();
    setShowSearch((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
    if (showSearch[key]) setSearchQuery('');
  };

  const toggleFilter = (event) => {
    event.stopPropagation();
    setShowFilter((prev) => !prev);
    if (showFilter) {
      setFilterByComputers(false);
      setFilterByDockingStations(false);
    }
  };

  const updateTabPosition = (tabKey) => {
    const tabElements = tabsRef.current?.querySelectorAll('.MuiTab-root');
    const index = tabLabels.indexOf(tabKey);
    if (tabElements && index !== -1) {
      const tabElement = tabElements[index];
      setTabPosition({ left: tabElement.offsetLeft, width: tabElement.offsetWidth });
    }
  };

  const handleSearchChange = (event) => setSearchQuery(event.target.value.toLowerCase());

  const handleFilterChange = (filterType) => {
    if (filterType === 'computers') {
      setFilterByComputers((prev) => !prev);
    } else if (filterType === 'dockingStations') {
      setFilterByDockingStations((prev) => !prev);
    }
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter({
      ...statusFilter,
      [event.target.name]: event.target.checked
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => setSnackbarMessage('Link copied'))
      .catch((error) => console.error('Error copying link:', error));
  };

  const handleShareClick = () => {
    // Set the access type based on the current value in projectSummary
    setAccessType(projectSummary?.public ? 'public' : 'restricted');
    setModalOpen(true);
  };
  



  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const handleOpenUploadModal  = () => {
    setUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setUploadModalOpen(false);
  };
  const handleFileUpload = async (rows) => {
    try {
      const batch = firestore.batch(); // Use batch to send multiple updates
  
      rows.forEach(row => {
        const [
          username, origin, destination, 
          originBuilding, originFloor, 
          destinationBuilding, destinationFloor, 
          notes
        ] = row;
  
        const validUsername = username ? `${username}` : '';
        const validOrigin = origin ? `${origin}` : '';
        const validDestination = destination ? `${destination}` : '';
        const validOriginBuilding = originBuilding ? `${originBuilding}` : '';
        const validOriginFloor = originFloor ? `${originFloor}` : '';
        const validDestinationBuilding = destinationBuilding ? `${destinationBuilding}` : '';
        const validDestinationFloor = destinationFloor ? `${destinationFloor}` : '';
        const validNotes = notes ? `${notes}` : '';
  
        if (username || origin || destination || originBuilding || originFloor || destinationBuilding || destinationFloor || notes) {
          const docId = uuidv4();
          const refLocation = `${projectSummary.path}/${projectSummary.id}`
          const docRef = firestore.collection(`${refLocation}/workstations`).doc(docId);
  
          // Create an object with only non-empty fields
          const dataToSubmit = {
            username: validUsername,
            origin: validOrigin,
            destination: validDestination,
          };
  
          // Add optional fields to dataToSubmit only if they have data
          if (validOriginBuilding) dataToSubmit.originBuilding = validOriginBuilding;
          if (validOriginFloor) dataToSubmit.originFloor = validOriginFloor;
          if (validDestinationBuilding) dataToSubmit.destinationBuilding = validDestinationBuilding;
          if (validDestinationFloor) dataToSubmit.destinationFloor = validDestinationFloor;
          if (validNotes) dataToSubmit.notes = validNotes;
  
          batch.set(docRef, dataToSubmit);
        }
      });
  
      // Commit the batch update
      await batch.commit();
  
      console.log("Workstations added successfully!");
      handleCloseUploadModal();
    } catch (error) {
      console.error("Error updating Firestore: ", error);
    }
  };



  const handleAccessChange = (event) => {
    const newAccessType = event.target.value;
    setAccessType(newAccessType);

    firestore.doc(`${projectSummary.path}/${projectSummary.id}`).update({
      public: newAccessType === 'public'
    }).catch((error) => console.error('Error updating public status:', error));

    setSnackbarMessage('Access Updated');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleReportLink = () => {
    const transformedData = filteredWorkstations.map(item => {
      let status = 'Disconnected';
      if (item.qa && item.qa.completedBy) {
        if (item.issues && Object.values(item.issues).some(issue => issue.clientIssue && !issue.resolvedBy)) {
          status = "QA'd with Issue";
        } else {
          status = "QA'd";
        }
      } else if (item.disconnectOnly) {
        status = 'Disconnect Only';
      } else if (item.issues && Object.values(item.issues).some(issue => issue.clientIssue && !issue.resolvedBy)) {
        status = 'Issue';
      } else if (item.reconnect && item.reconnect.completedBy) {
        status = 'Reconnected';
      } else if (!item.documentBy || item.documentBy === '') {
        status = 'Imported';
      }

      return {
        username: item.username,
        origin: item.origin,
        destination: item.destination,
        issues: item.issues ? Object.keys(item.issues).length : 0,
        status: status,
      };
    });

    const csv = Papa.unparse(transformedData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'workstations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseUsernameFilterMenu = () => {
    setAnchorElUsernameFilter(null);
  };


  const sortedWorkstations = sortWorkstations(workstations, sortConfig);
  const filteredWorkstations = filterWorkstations(sortedWorkstations, { filterByComputers, filterByDockingStations, status: statusFilter }, searchQuery, sortConfig);

  const getTabLabel = (key) => (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', maxWidth: '100%' }}>
      <Typography
        variant="body1"
        sx={{ mr: 0.5, fontWeight: sortConfig.key === key ? 'bold' : 'normal', whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {key.charAt(0).toUpperCase() + key.slice(1)}
      </Typography>
      {sortConfig.key === key && (
        <>
          {sortConfig.direction === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
          {(key === 'username' || key === 'destination') && (
            <IconButton size="small" onClick={(event) => toggleSearch(event, key)}>
              <SearchIcon fontSize="small" />
            </IconButton>
          )}
          {key === 'username' && (
            <IconButton size="small" onClick={(event) => {
              event.stopPropagation();
              setAnchorElUsernameFilter(event.currentTarget);
            }}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          )}
          {key === 'status' && (
            <IconButton size="small" onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );



  if (!projectSummary) return <></>;
  // If projectSummary is public, skip the rest of the checks
  if (!projectSummary?.public) {
    if (!auth.uid) return <></>;
    if (profile.role !== 'admin' && profile.role !== 'supervisor' && profile.role !== 'client') return <></>;
  }


  const projectDateTime = moment.unix(projectSummary.date.seconds).format('dddd, MMMM Do, h:mm A');
  return (
    <Container maxWidth="md" sx={{ marginTop: 10, position: 'relative' }}>
      <Container sx={{ 
        paddingLeft: { xs: '0px', sm: '0px', md: '0px', lg: '0px', xl: '0px' },
        paddingRight: { xs: '0px', sm: '0px', md: '0px', lg: '0px', xl: '0px' },
      }}>
        <Title title={`${projectSummary.name} - ${projectDateTime}`} />
        <ProjectAnalytics workstations={filteredWorkstations} />
      </Container>

      <AppBar position="sticky" color="default">
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(1) }}>
          <Tabs
            value={sortConfig.key}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="standard"
            variant="fullWidth"
            ref={tabsRef}
            sx={{ flexGrow: 1, borderBottom: 1, borderColor: 'divider', overflowX: 'auto' }}
          >
            {tabLabels.map((label) => (
              <Tab key={label} label={getTabLabel(label)} value={label} onClick={() => handleTabClick(label)} />
            ))}
          </Tabs>
          {showSearch[sortConfig.key] && (sortConfig.key === 'username' || sortConfig.key === 'destination') && (
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(0.5, 2),
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                marginLeft: `${tabPosition.left}px`,
                width: `${tabPosition.width}px`,
                zIndex: 1
              }}
            >
              <InputBase
                placeholder={`Search ${sortConfig.key}...`}
                value={searchQuery}
                onChange={handleSearchChange}
                autoFocus
                sx={{ width: '100%' }}
              />
            </Box>
          )}
        </Box>
      </AppBar>
      <Workstations users={usersData} workstations={filteredWorkstations} />
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
        {auth.uid && (
          <Button onClick={handleShareClick} variant="contained">
            Share
          </Button>
        )}
        <Button onClick={handleReportLink} variant="contained">
          Download Report
        </Button>
        {auth.uid && (profile.role === 'admin' || profile.role === 'supervisor') && (
            <>
              <Button onClick={handleOpenUploadModal} variant="contained">
                Upload
              </Button>
              <UploadModal
                uploadModalOpen={uploadModalOpen}
                handleCloseUploadModal={handleCloseUploadModal}
                handleFileUpload={handleFileUpload}
                requiredColumns={[
                  { name: 'username', label: 'Username' },
                  { name: 'origin', label: 'Origin' },
                  { name: 'destination', label: 'Destination' },
                ]}
                optionalColumns={[
                  { name: 'originBuilding', label: 'Origin Building' },
                  { name: 'originFloor', label: 'Origin Floor' },
                  { name: 'destinationBuilding', label: 'Destination Building' },
                  { name: 'destinationFloor', label: 'Destination Floor' },
                  { name: 'notes', label: 'Notes' },
                ]}
              />
            </>
          )}
      </Box>
      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
        <img src={mmaLogo} style={{ maxWidth: 100 }} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={statusFilter.disconnectOnly}
                onChange={handleStatusFilterChange}
                name="disconnectOnly"
              />
            }
            label="Disconnect Only"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={statusFilter.qa}
                onChange={handleStatusFilterChange}
                name="qa"
              />
            }
            label="QA'd"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={statusFilter.issue}
                onChange={handleStatusFilterChange}
                name="issue"
              />
            }
            label="Issue"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={statusFilter.reconnected}
                onChange={handleStatusFilterChange}
                name="reconnected"
              />
            }
            label="Reconnected"
          />
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorElUsernameFilter}
        open={Boolean(anchorElUsernameFilter)}
        onClose={handleCloseUsernameFilterMenu}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterByComputers}
                onChange={() => handleFilterChange('computers')}
              />
            }
            label="Has Computer"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterByDockingStations}
                onChange={() => handleFilterChange('dockingStations')}
              />
            }
            label="Has Docking Station"
          />
        </MenuItem>
      </Menu>


      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="share-modal-title" variant="h6" component="h2">
            General Access
          </Typography>
          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <RadioGroup
              aria-label="access"
              name="access"
              value={accessType}
              onChange={handleAccessChange}
            >
              <FormControlLabel value="restricted" control={<Radio />} label="Restricted - Only people with access can open with the link" />
              <FormControlLabel value="public" control={<Radio />} label="Anyone with the link - Anyone on the internet with the link can view" />
            </RadioGroup>
            <FormHelperText>Choose the access level for the project.</FormHelperText>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button onClick={handleCopyLink} variant="outlined">Copy Link</Button>
            <Button onClick={handleCloseModal} variant="contained">Done</Button>
          </Box>
        </Box>
      </Modal>

    </Container>
  );
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  projectSummary: state.firestore.data.projectSummary,
  workstations: state.firestore.data.workstations,
  profile: state.firebase.profile,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: 'projects',
      doc: props.params.year,
      subcollections: [{ collection: props.params.month, doc: props.params.id }],
      storeAs: 'projectSummary'
    },
    {
      collection: 'projects',
      doc: props.params.year,
      subcollections: [
        { collection: props.params.month, doc: props.params.id, subcollections: [{ collection: 'workstations' }] }
      ],
      storeAs: 'workstations'
    }
  ])
)(Project);