import React from 'react';
import { Modal, Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';

const ShareModal = ({ modalOpen, handleCloseModal, accessType, handleAccessChange, handleCopyLink }) => {
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="share-modal-title"
      aria-describedby="share-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography id="share-modal-title" variant="h6" component="h2">
          General Access
        </Typography>
        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <RadioGroup
            aria-label="access"
            name="access"
            value={accessType}
            onChange={handleAccessChange}
          >
            <FormControlLabel
              value="restricted"
              control={<Radio />}
              label="Restricted - Only people with access can open with the link"
            />
            <FormControlLabel
              value="public"
              control={<Radio />}
              label="Anyone with the link - Anyone on the internet with the link can view"
            />
          </RadioGroup>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button onClick={handleCopyLink} variant="outlined">Copy Link</Button>
          <Button onClick={handleCloseModal} variant="contained">Done</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareModal;
