import { useState } from 'react';

const useShareModal = (url, setSnackbarMessage) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url)
      .then(() => setSnackbarMessage('Link copied to clipboard!'))
      .catch(() => setSnackbarMessage('Failed to copy link.'));
  };

  return {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    handleCopyLink,
  };
};

export default useShareModal;
