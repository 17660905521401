import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Container, Box, Card, TextField, Grid, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { signUp } from '../../store/actions/authActions';
import mmaLogo from '../../assets/mma-logo.png';

class SignUp extends Component {
  state = {
    form: {
      email: '',
      password: '',
      repeatPassword: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
    showPasswordError: false
  }

  handleChange = (e) => {
    const { form } = this.state;
    const currentState = { ...form };
    const { id, value } = e.target;
    currentState[id] = value;
    this.setState({ form: currentState });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.form.password !== this.state.form.repeatPassword) {
      this.setState({ showPasswordError: true });
    } else {
      this.setState({ showPasswordError: false });
      this.props.signUp(this.state.form);
    }
  }

  render() {
    const { auth } = this.props;
    if (auth.uid) return <Routes><Route path="*" element={<Navigate replace to="/" />} /></Routes>;

    return (
      <Container maxWidth="md">
        <form onSubmit={this.handleSubmit}>
          <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
            <Grid container display="flex" justifyContent="center" alignItems="center" padding="10px" spacing={0}>
              <Grid>
                <Card>
                  <Grid container display="flex" justifyContent="center" alignItems="center" padding="10px" spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h5" align="center" color="textSecondary">
                        Sign Up
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Email"
                        id="email"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        type="email"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Password"
                        id="password"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        type="password"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Repeat Password"
                        id="repeatPassword"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        type="password"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="First Name"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Last Name"
                        id="lastName"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Phone Number - ex: 925-808-1234"
                        id="phone"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button type="submit" fullWidth variant="contained" color="primary">
                        Sign Up
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      {this.state.showPasswordError && (
                        <Typography variant="body2" color="error" align="center">
                          Error: Passwords do not match
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img alt="murphy mckay logo" src={mmaLogo} style={{ maxWidth: 100 }} />
                </div>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
