import React, { useState, useRef, useEffect } from 'react';
import {
  Typography, Container, Button, AppBar, Tabs, Tab, Box, useTheme, InputBase, IconButton, Checkbox, FormControlLabel, Snackbar, Menu, MenuItem, Modal, RadioGroup, FormControl, Radio, FormHelperText
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ProjectAnalytics from './ProjectAnalytics';
import ServerProjectAnalytics from './ServerProjectAnalytics';
import Workstations from './workstations/Workstations';
import Servers from './servers/Servers';
import mmaLogo from '../../assets/mma-logo.png';
import Papa from 'papaparse';
import Title from './Title';
import { useFirestore } from 'react-redux-firebase';
import UploadModal from './Modals/UploadModal';
import ShareModal from './Modals/ShareModal';
import { v4 as uuidv4 } from 'uuid'
import useShareModal from '../../hooks/useShareModal';

const serverTabLabels = ['type', 'model', 'destination Rack'];
const tabLabels = ['username', 'destination', 'issues', 'status'];

const colorPriority = { red: 1, orange: 2, yellow: 3, greenDisconnect: 4, greenQA: 5, greenWithRedBorder: 6, white: 7, black: 8 };

const getColorPriority = (workstation) => {
  if (workstation.qa && workstation.qa.completedBy) {
    if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) {
      return 'greenWithRedBorder';
    }
    return 'greenQA';
  }
  if (workstation.disconnectOnly) return 'greenDisconnect';

  // For import only
  if (!workstation.documentBy || workstation.documentBy === "") return 'black';

  if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) return 'red';
  
  return workstation.reconnect?.completedBy ? 'yellow' : 'orange';
};

const compareValues = (a, b, key) => {
  const valueA = a[key];
  const valueB = b[key];

  // Convert both values to strings if they are not numbers
  const valueAStr = typeof valueA === 'string' ? valueA : (valueA ?? '').toString();
  const valueBStr = typeof valueB === 'string' ? valueB : (valueB ?? '').toString();

  switch (key) {
    case 'issues':
      return Object.values(a.issues || {}).filter(issue => issue.clientIssue).length - 
             Object.values(b.issues || {}).filter(issue => issue.clientIssue).length;
    case 'status':
      return colorPriority[getColorPriority(a)] - colorPriority[getColorPriority(b)];
    default:
      // Safely compare as strings, accounting for special characters or empty values
      return valueAStr.localeCompare(valueBStr);
  }
};


const sortWorkstations = (workstations, sortConfig) => {
  return Object.values(workstations || {}).sort((a, b) => {
    const comparison = compareValues(a, b, sortConfig.key);
    return sortConfig.direction === 'asc' ? comparison : -comparison;
  });
};

const filterWorkstations = (workstations, filters, searchQuery, sortConfig) => {
  return workstations.filter((workstation) => {
    const value = workstation[sortConfig.key] ?? '';
    const matchesSearch = searchQuery
      ? value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    const matchesFilters = (!filters.filterByComputers || Object.keys(workstation.computers || {}).length > 0)
      && (!filters.filterByDockingStations || Object.keys(workstation.dockingStations || {}).length > 0)
      && (!filters.status.disconnectOnly || workstation.disconnectOnly)
      && (!filters.status.qa || (workstation.qa && workstation.qa.completedBy))
      && (!filters.status.issue || (workstation.issues && Object.values(workstation.issues).some(issue => issue.clientIssue && !issue.resolvedBy)))
      && (!filters.status.reconnected || workstation.reconnect?.completedBy);

    return matchesSearch && matchesFilters;
  });
};

function Project({ auth, role, title, workstations, servers, url, selectedProject }) {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    handleCopyLink,
  } = useShareModal(url, setSnackbarMessage);

  const theme = useTheme();
  const [usersData, setUsersData] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: 'username', direction: 'asc' });
  const [showSearch, setShowSearch] = useState({ username: false, destination: false });
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [filterByComputers, setFilterByComputers] = useState(false);
  const [filterByDockingStations, setFilterByDockingStations] = useState(false);
  const [statusFilter, setStatusFilter] = useState({
    disconnectOnly: false,
    qa: false,
    issue: false,
    reconnected: false
  });
  const [tabPosition, setTabPosition] = useState({ left: 0, width: 0 });
  const [linkCopied, setLinkCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUsernameFilter, setAnchorElUsernameFilter] = useState(null);
  const tabsRef = useRef(null);
  const [accessType, setAccessType] = useState(null);
  const firestore = useFirestore();
  const [projectType, setProjectType] = useState('workstations'); // Default to "workstations"

  console.log(selectedProject);
  useEffect(() => {
    if (selectedProject) {

      const refLocation = selectedProject?.refLocation?.path;
      const parts = refLocation.split('/');
      const inferredType = parts.includes('serverProjects') ? 'servers' : 'workstations';
      setProjectType(inferredType);

      firestore.doc(refLocation).get().then((doc) => {
        if (doc.exists) {
          const projectData = doc.data();
          setAccessType(projectData.public ? 'public' : 'restricted');
        }
      }).catch((error) => console.error('Error fetching project data:', error));
      
    }
  }, [selectedProject]);

  useEffect(() => {
    if (auth.uid) {
      firestore.collection('users').get().then((snapshot) => {
        const users = snapshot.docs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.data() }), {});
        setUsersData(users);
      }).catch((error) => console.error('Error fetching users:', error));
    }
  }, [firestore, auth.uid]);

  useEffect(() => {
    updateTabPosition(sortConfig.key);
  }, [sortConfig.key]);

  useEffect(() => {
    if (showSearch.username || showSearch.destination || showFilter) {
      updateTabPosition(sortConfig.key);
    }
  }, [showSearch, showFilter]);

  const handleTabChange = (event, newValue) => {
    setSortConfig({ key: newValue, direction: 'asc' });
    setShowSearch({ username: false, destination: false });
    setSearchQuery('');
    setShowFilter(false);
    updateTabPosition(newValue);
  };

  const handleTabClick = (key) => {
    setSortConfig((prev) => ({
      key, direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
    updateTabPosition(key);
  };

  const toggleSearch = (event, key) => {
    event.stopPropagation();
    setShowSearch((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
    if (showSearch[key]) setSearchQuery('');
  };

  const toggleFilter = (event) => {
    event.stopPropagation();
    setShowFilter((prev) => !prev);
    if (showFilter) {
      setFilterByComputers(false);
      setFilterByDockingStations(false);
    }
  };

  const updateTabPosition = (tabKey) => {
    const tabElements = tabsRef.current?.querySelectorAll('.MuiTab-root');
    
    // const index = tabLabels.indexOf(tabKey);
    const index = (projectType === 'servers' ? serverTabLabels : tabLabels).indexOf(tabKey);

    if (tabElements && index !== -1) {
      const tabElement = tabElements[index];
      setTabPosition({ left: tabElement.offsetLeft, width: tabElement.offsetWidth });
    }
  };

  const handleSearchChange = (event) => setSearchQuery(event.target.value.toLowerCase());

  const handleFilterChange = (filterType) => {
    if (filterType === 'computers') {
      setFilterByComputers((prev) => !prev);
    } else if (filterType === 'dockingStations') {
      setFilterByDockingStations((prev) => !prev);
    }
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter({
      ...statusFilter,
      [event.target.name]: event.target.checked
    });
  };

  const sortedWorkstations = sortWorkstations(workstations, sortConfig);
  const filteredWorkstations = filterWorkstations(sortedWorkstations, { filterByComputers, filterByDockingStations, status: statusFilter }, searchQuery, sortConfig);

  const getTabLabel = (key) => (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', maxWidth: '100%' }}>
      <Typography
        variant="body1"
        sx={{ mr: 0.5, fontWeight: sortConfig.key === key ? 'bold' : 'normal', whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {key.charAt(0).toUpperCase() + key.slice(1)}
      </Typography>
      {sortConfig.key === key && (
        <>
          {sortConfig.direction === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
          {(key === 'username' || key === 'destination') && (
            <IconButton size="small" onClick={(event) => toggleSearch(event, key)}>
              <SearchIcon fontSize="small" />
            </IconButton>
          )}
          {key === 'username' && (
            <IconButton size="small" onClick={(event) => {
              event.stopPropagation();
              setAnchorElUsernameFilter(event.currentTarget);
            }}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          )}
          {key === 'status' && (
            <IconButton size="small" onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const handleOpenUploadModal  = () => {
    setUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setUploadModalOpen(false);
  };

  const handleFileUpload = async (rows) => {
    try {
      const batch = firestore.batch(); // Use batch to send multiple updates
  
      rows.forEach(row => {
        const dataToSubmit = {};
  
        if (projectType === 'workstations') {
          // Map values for workstations
          const [
            username, origin, destination,
            originBuilding, originFloor,
            destinationBuilding, destinationFloor,
            notes
          ] = row;
  
          // Explicitly convert specified fields to strings
          // Mandatory fields
          if (username) dataToSubmit.username = `${username}`;
          if (origin) dataToSubmit.origin = `${origin}`;
          if (destination) dataToSubmit.destination = `${destination}`;
  
          // Optional fields
          if (originBuilding) dataToSubmit.originBuilding = `${originBuilding}`;
          if (originFloor) dataToSubmit.originFloor = `${originFloor}`;
          if (destinationBuilding) dataToSubmit.destinationBuilding = `${destinationBuilding}`;
          if (destinationFloor) dataToSubmit.destinationFloor = `${destinationFloor}`;
          if (notes) dataToSubmit.notes = `${notes}`;
        } else if (projectType === 'servers') {
          // Map values for servers
          const [
            originBuilding, size, originRow, originRack, originRackUnit,
            originRearMounted, destinationBuilding, destinationRow,
            destinationRack, destinationRackUnit, destinationRearMounted,
            type, model, serialNumber, destinationConfirmed,
            hasRails, ip, make, notes, originConfirmed, uid
          ] = row;
  
          // Explicitly set types for all fields
          // Mandatory fields
          if (originBuilding) dataToSubmit.originBuilding = `${originBuilding}`;
          if (size !== undefined && size !== null) dataToSubmit.size = Number(size);
          if (originRow) dataToSubmit.originRow = `${originRow}`;
          if (originRack) dataToSubmit.originRack = `${originRack}`;
          if (originRackUnit !== undefined && originRackUnit !== null) dataToSubmit.originRackUnit = Number(originRackUnit);

          if (destinationBuilding) dataToSubmit.destinationBuilding = `${destinationBuilding}`;
          if (destinationRow) dataToSubmit.destinationRow = `${destinationRow}`;
          if (destinationRack) dataToSubmit.destinationRack = `${destinationRack}`;
          if (destinationRackUnit !== undefined && destinationRackUnit !== null) dataToSubmit.destinationRackUnit = Number(destinationRackUnit);

          if (destinationRearMounted === 'TRUE' || destinationRearMounted === 'true') dataToSubmit.destinationRearMounted = true;
          if (originRearMounted === 'TRUE' || originRearMounted === 'true') dataToSubmit.originRearMounted = true;

          if (type) dataToSubmit.type = `${type}`;
          if (model) dataToSubmit.model = `${model}`;
          if (serialNumber) dataToSubmit.serialNumber = `${serialNumber}`;

          // Optional fields
          if (destinationConfirmed) {
            dataToSubmit.destinationConfirmed =
              destinationConfirmed === 'TRUE' || destinationConfirmed === 'true' ? true :
              destinationConfirmed === 'FALSE' || destinationConfirmed === 'false' ? false : null;
          }
          if (hasRails === 'TRUE' || hasRails === 'true') dataToSubmit.hasRails = true;
          if (ip) dataToSubmit.ip = `${ip}`;
          if (make) dataToSubmit.make = `${make}`;
          if (notes) dataToSubmit.notes = `${notes}`;
          if (originConfirmed === 'TRUE' || originConfirmed === 'true') dataToSubmit.originConfirmed = true;
          if (uid) dataToSubmit.uid = `${uid}`;
        }
  
        // Check if the row has at least one required field
        if (Object.keys(dataToSubmit).length > 0) {
          const docId = uuidv4();
          const collectionPath = `${selectedProject?.refLocation?.path}/${projectType}`;
          const docRef = firestore.collection(collectionPath).doc(docId);
  
          batch.set(docRef, dataToSubmit);
        }
      });
  
      // Commit the batch update
      await batch.commit();
  
      console.log(`${projectType.charAt(0).toUpperCase() + projectType.slice(1)} added successfully!`);
      handleCloseUploadModal();
    } catch (error) {
      console.error(`Error updating Firestore for ${projectType}: `, error);
    }
  };
  
  const handleAccessChange = (event) => {
    const newAccessType = event.target.value;
    setAccessType(newAccessType);

    // Update the Firestore document's public field
    firestore.doc(selectedProject?.refLocation?.path).update({
      public: newAccessType === 'public'
    })
    .then(() => {
      setSnackbarMessage('Access updated successfully');
    })
    .catch((error) => {
      console.error('Error updating public status:', error);
      setSnackbarMessage('Error updating access');
    });
  };
  
  const handleReportLink = () => {
    const transformedData = filteredWorkstations.map(item => {
      let status = 'Disconnected';
      if (item.qa && item.qa.completedBy) {
        if (item.issues && Object.values(item.issues).some(issue => issue.clientIssue && !issue.resolvedBy)) {
          status = "QA'd with Issue";
        } else {
          status = "QA'd";
        }
      } else if (item.disconnectOnly) {
        status = 'Disconnect Only';
      } else if (item.issues && Object.values(item.issues).some(issue => issue.clientIssue && !issue.resolvedBy)) {
        status = 'Issue';
      } else if (item.reconnect && item.reconnect.completedBy) {
        status = 'Reconnected';
      } else if (!item.documentBy || item.documentBy === '') {
        status = 'Imported';
      }

      return {
        username: item.username,
        origin: item.origin,
        destination: item.destination,
        issues: item.issues ? Object.keys(item.issues).length : 0,
        status: status,
      };
    });

    const csv = Papa.unparse(transformedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'workstations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseUsernameFilterMenu = () => {
    setAnchorElUsernameFilter(null);
  };

  if (!title) return <></>;

  return (
    <>
      <Title title={title} />
      {projectType === 'servers' ? (
        <ServerProjectAnalytics servers={servers} />
      ) : (
        <ProjectAnalytics workstations={filteredWorkstations} />
      )}
      <Box sx={{ minHeight: '100vh' }}>
        <AppBar 
          position="sticky" 
          color="default"
          sx={{ 
            top: 0,
            zIndex: (theme) => theme.zIndex.appBar + 1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(1) }}>
            <Tabs
              value={sortConfig.key}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="standard"
              variant="fullWidth"
              ref={tabsRef}
              sx={{ flexGrow: 1, borderBottom: 1, borderColor: 'divider', overflowX: 'auto' }}
            >
              {(projectType === 'servers' ? serverTabLabels : tabLabels).map((label) => (
                <Tab key={label} label={getTabLabel(label)} value={label} onClick={() => handleTabClick(label)} />
              ))}
            </Tabs>
            {showSearch[sortConfig.key] && (sortConfig.key === 'username' || sortConfig.key === 'destination') && (
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: theme.shape.borderRadius,
                  padding: theme.spacing(0.5, 2),
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                  marginLeft: `${tabPosition.left}px`,
                  width: `${tabPosition.width}px`,
                  zIndex: 1
                }}
              >
                <InputBase
                  placeholder={`Search ${sortConfig.key}...`}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  autoFocus
                  sx={{ width: '100%' }}
                />
              </Box>
            )}
          </Box>
        </AppBar>
        {projectType === 'servers' ? (
          <Servers servers={servers} />
        ) : (
          <Workstations users={usersData} workstations={filteredWorkstations} />
        )}
        <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
          {auth.uid && (
            <Button onClick={handleOpenModal} variant="contained">
              Share
            </Button>
          )}
          <Button onClick={handleReportLink} variant="contained">
            Download Report
          </Button>
          {auth.uid && (role === 'admin' || role === 'supervisor') && (
            <>
              <Button onClick={handleOpenUploadModal} variant="contained">
                Upload
              </Button>
              <UploadModal
                uploadModalOpen={uploadModalOpen}
                handleCloseUploadModal={handleCloseUploadModal}
                handleFileUpload={handleFileUpload}
                requiredColumns={
                  projectType === 'workstations'
                    ? [
                        { name: 'username', label: 'Username' },
                        { name: 'origin', label: 'Origin' },
                        { name: 'destination', label: 'Destination' },
                      ]
                    : [
                        { name: 'originBuilding', label: 'Origin Lab/Room' },
                        { name: 'size', label: 'Size' },
                        { name: 'originRow', label: 'Origin Row' },
                        { name: 'originRack', label: 'Origin Rack' },
                        { name: 'originRackUnit', label: 'Origin RU' },
                        { name: 'originRearMounted', label: 'Origin Rear Mount' },
                        { name: 'destinationBuilding', label: 'Destination Lab/Room' },
                        { name: 'destinationRow', label: 'Destination Row' },
                        { name: 'destinationRack', label: 'Destination Rack' },
                        { name: 'destinationRackUnit', label: 'Destination RU' },
                        { name: 'destinationRearMounted', label: 'Destination Rear Mount' },
                        { name: 'type', label: 'Type' },
                        { name: 'model', label: 'Model' },
                        { name: 'serialNumber', label: 'S/N' },
                      ]
                }
                optionalColumns={
                  projectType === 'workstations'
                    ? [
                        { name: 'originBuilding', label: 'Origin Building' },
                        { name: 'originFloor', label: 'Origin Floor' },
                        { name: 'destinationBuilding', label: 'Destination Building' },
                        { name: 'destinationFloor', label: 'Destination Floor' },
                        { name: 'notes', label: 'Notes' },
                      ]
                    : [
                        { name: 'destinationConfirmed', label: 'Destination Confirmed' },
                        { name: 'hasRails', label: 'Has Rails' },
                        { name: 'ip', label: 'IP' },
                        { name: 'make', label: 'Make' },
                        { name: 'notes', label: 'Notes' },
                        { name: 'originConfirmed', label: 'Origin Confirmed' },
                        { name: 'uid', label: 'UID' },
                      ]
                }
              />
            </>
          )}
        </Box>

      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
        <img src={mmaLogo} style={{ maxWidth: 100 }} alt="Logo" />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={statusFilter.disconnectOnly}
                onChange={handleStatusFilterChange}
                name="disconnectOnly"
              />
            }
            label="Disconnect Only"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={statusFilter.qa}
                onChange={handleStatusFilterChange}
                name="qa"
              />
            }
            label="QA'd"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={statusFilter.issue}
                onChange={handleStatusFilterChange}
                name="issue"
              />
            }
            label="Issue"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={statusFilter.reconnected}
                onChange={handleStatusFilterChange}
                name="reconnected"
              />
            }
            label="Reconnected"
          />
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorElUsernameFilter}
        open={Boolean(anchorElUsernameFilter)}
        onClose={handleCloseUsernameFilterMenu}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterByComputers}
                onChange={() => handleFilterChange('computers')}
              />
            }
            label="Has Computer"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterByDockingStations}
                onChange={() => handleFilterChange('dockingStations')}
              />
            }
            label="Has Docking Station"
          />
        </MenuItem>
      </Menu>

      <ShareModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        accessType={accessType}
        handleAccessChange={handleAccessChange}
        handleCopyLink={handleCopyLink}
      />

      {snackbarMessage && (
        <Snackbar
          open={!!snackbarMessage}
          autoHideDuration={3000}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      )}

    </>
  );
}

export default Project;
