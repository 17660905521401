import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';

function ServerProjectAnalytics({ servers: devices }) {
  // Provide initial totals
  const [totals, setTotals] = useState({
    devices: 0,
    servers: 0,
    pcs: 0,
    switches: 0,
    pdus: 0,
    kvms: 0,
    patchpanels: 0,
    chassis: 0,
    shelfs: 0,
  });

  // Map device "type" to the corresponding key in 'totals'
  const typeToKeyMap = {
    'Chassis': 'chassis',
    'Devices': 'devices',
    'KVM': 'kvms',
    'Patch Panel': 'patchpanels',
    'PC': 'pcs',
    'Power': 'pdus',   // "Power" counted as PDUs
    'Server': 'servers',
    'Shelf': 'shelfs',
    'Switch': 'switches',
  };

  useEffect(() => {
    // If 'devices' isn't a valid array, reset and skip
    if (!Array.isArray(devices)) {
      setTotals({
        devices: 0,
        servers: 0,
        pcs: 0,
        switches: 0,
        pdus: 0,
        kvms: 0,
        patchpanels: 0,
        chassis: 0,
        shelfs: 0,
      });
      return;
    }

    // Start fresh totals
    const newTotals = {
      devices: 0,
      servers: 0,
      pcs: 0,
      switches: 0,
      pdus: 0,
      kvms: 0,
      patchpanels: 0,
      chassis: 0,
      shelfs: 0,
    };

    devices.forEach(device => {
      // Every device increments the 'devices' count
      newTotals.devices += 1;

      // If there's a recognized type, increment that type as well
      const deviceType = device?.type;
      if (deviceType && typeToKeyMap[deviceType]) {
        newTotals[typeToKeyMap[deviceType]] += 1;
      }
    });

    setTotals(newTotals);
  }, [devices]);

  // Mapping for how the totals are displayed
  const coreDevicesToKeyMapping = {
    'Servers': 'servers',
    'PCs': 'pcs',
    'Switches': 'switches',
    'PDUs': 'pdus',
  };

  const accessoriesToKeyMapping = [
    { label: 'Devices', key: 'devices' },
    { label: 'KVMs', key: 'kvms' },
    { label: 'Patch Panels', key: 'patchpanels' },
    { label: 'Chassis', key: 'chassis' },
    { label: 'Shelfs', key: 'shelfs' },
  ];

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch" marginBottom="22px">
        {Object.entries(coreDevicesToKeyMapping).map(([label, key]) => (
          <Grid item xs={12} sm={6} md={3} key={label}>
            <Paper elevation={2} sx={{ padding: 2 }}>
              <Typography variant="h6" textAlign="center">{label}</Typography>
              <Typography variant="h5" textAlign="center">{totals[key]}</Typography>
            </Paper>
          </Grid>
        ))}

      <Grid item xs={12}>
        <Paper elevation={1} sx={{ padding: 3, textAlign: 'center' }}>
          <Typography variant="h5" mb={3}>
            Devices and Accessories
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {accessoriesToKeyMapping.map(({ label, key }) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={key}>
                <Paper
                  elevation={1}
                  sx={{
                    padding: 2, 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center', 
                    alignItems: 'center' 
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {label}
                  </Typography>
                  <Typography variant="body1">
                    {totals[key] || 0}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>


      </Grid>
    </>
  );
}

export default ServerProjectAnalytics;