import React, { useState } from 'react';
import { Button, Box, Typography, Modal, FormGroup, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function UploadModal({ 
  uploadModalOpen, 
  handleCloseUploadModal, 
  handleFileUpload, 
  requiredColumns = [], 
  optionalColumns = [] 
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [optionalColumnsState, setOptionalColumnsState] = useState(
    optionalColumns.reduce((acc, column) => ({ ...acc, [column.name]: false }), {})
  );

  const [isUploading, setIsUploading] = useState(false);

  const handleCheckboxChange = (event) => {
    setOptionalColumnsState({
      ...optionalColumnsState,
      [event.target.name]: event.target.checked,
    });
  };

  const createAndDownloadTemplate = () => {
    const worksheetData = [requiredColumns.map(column => column.name)];
    const selectedOptionalColumns = Object.keys(optionalColumnsState).filter(column => optionalColumnsState[column]);
    worksheetData[0].push(...selectedOptionalColumns);

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(fileBlob, 'template.xlsx');
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUploadClick = () => {
    if (selectedFile) {
      setIsUploading(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        const workbook = XLSX.read(fileData, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const rows = data.slice(1); 
        handleFileUpload(rows);
        setIsUploading(false);
        handleCloseUploadModal(); // Close modal after upload
      };
      reader.readAsBinaryString(selectedFile);
    } else {
      alert('Please select a file to upload.');
    }
  };

  return (
    <Modal 
      open={uploadModalOpen}
      onClose={handleCloseUploadModal}
      aria-labelledby="upload-modal-title"
      aria-describedby="upload-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: 400 },
        maxHeight: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
      }}>
        <Typography id="upload-modal-title" variant="h6" component="h2">
          Select fields to customize your template. Download, fill it out, and upload.
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Required Fields:</Typography>
          <FormGroup>
            {requiredColumns.map((column) => (
              <FormControlLabel
                key={column.name}
                control={<Checkbox checked disabled />}
                label={column.label}
              />
            ))}
          </FormGroup>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">Optional Fields:</Typography>
          <FormGroup>
            {optionalColumns.map((column) => (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    name={column.name}
                    checked={optionalColumnsState[column.name]}
                    onChange={handleCheckboxChange}
                  />
                }
                label={column.label}
              />
            ))}
          </FormGroup>
        </Box>

        <Button onClick={createAndDownloadTemplate} variant="contained" fullWidth sx={{ mt: 3 }}>
          Download Template
        </Button>

        <input
          type="file"
          onChange={handleFileChange}
          style={{ marginTop: '20px', marginBottom: '20px', display: 'block' }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button onClick={handleCloseUploadModal} variant="outlined" disabled={isUploading}>
            Cancel
          </Button>
          <Button 
            onClick={handleFileUploadClick} 
            variant="contained" 
            disabled={isUploading || !selectedFile}
            startIcon={isUploading && <CircularProgress size={20} />}
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </Box>

      </Box>
    </Modal>
  );
}

export default UploadModal;
