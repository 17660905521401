import React, { useState } from 'react';
import { Grid, Button, Paper, Typography, Box, Container } from '@mui/material';
import emptyPic from '../../../assets/no-pic-loaded.PNG';
import './BasicInfo.css';
import 'react-image-gallery/styles/css/image-gallery.css';

function BasicInfo({ server = {} }) {

  const Field = ({ label, value }) => {
    return value ? (
      <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
        {`${label}: ${value}`}
      </Typography>
    ) : null;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} display="flex" flexDirection="column" alignItems="center">
        <img
          src={server.pictures?.[0] || emptyPic}
          style={{ maxHeight: 250, maxWidth: '100%' }}
          alt="server preview"
        />

      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5" textAlign="center" sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' }, fontWeight: 'bold' }}>
          {server.model || ''}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>Origin</Typography>
                <Field label="Building" value={server.originBuilding} />
                <Field label="Rack" value={server.originRack} />
                <Field label="Rack Unit" value={server.originRackUnit} />
                <Field label="Row" value={server.row} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>Destination</Typography>
                <Field label="Building" value={server.destinationBuilding} />
                <Field label="Rack" value={server.destinationRack} />
                <Field label="Rack Unit" value={server.destinationRackUnit} />
                <Field label="Row" value={server.destinationRow} />
            </Paper>
          </Grid>
          
        </Grid>

      </Grid>
    </Grid>
  );
}

export default BasicInfo;
